import React, { ReactNode } from 'react';

// Types
import { UserContext } from '@zola-helpers/client/dist/es/@types';

// Components
import Nav from '~/components/Nav';

// Constants
import { PrimaryNavIdentifier } from '@zola-helpers/client/dist/es/constants/navConstants';

// Utils
import { useIsPostAuthNav3MobileOrDesktop } from '~/hooks/useIsPostAuthNav3Active';

// Styles
import Footer from '~/components/ui/Footer/Footer';
import { Container, InnerContainer, ContentWrapper } from './FaqPagesContainer.styles';

type FaqPagesContainerProps = {
  className?: string;
  navHTML: string;
  userCtx: UserContext;
  children: ReactNode;
};

const FaqPagesContainer = ({
  className,
  children,
  userCtx,
  navHTML,
}: FaqPagesContainerProps): JSX.Element => {
  const isPostAuthNav3Enabled = useIsPostAuthNav3MobileOrDesktop(userCtx);

  return (
    <Container className={className}>
      <Nav
        navHTML={navHTML}
        showPromo
        user={userCtx}
        disableSecondary
        disablePrimaryNavCollapse
        {...(isPostAuthNav3Enabled && { activePrimaryLink: PrimaryNavIdentifier.HELP })}
      />
      <InnerContainer className={className} isPostAuthNav3={isPostAuthNav3Enabled}>
        <ContentWrapper>{children}</ContentWrapper>
        <Footer userCtx={userCtx} />
      </InnerContainer>
    </Container>
  );
};

export default FaqPagesContainer;
